<template>
  <section class="container py-4">
    <div v-if="loadingASPSPs" class="text-center">
      <LoaderPic />Loading...
    </div>
    <div v-else>
      <div class="form-row align-items-center" style="margin-bottom:40px">
        <div class="col">
          <select class="form-control" required v-model="endpoint"
            v-on:change="option = '', results = [], showAvailableTransaction = false">
            <option value disabled selected>Data</option>
            <option v-for="(endpoint, value) in dataType" :value="endpoint">{{ value }}</option>
          </select>
        </div>
        <div class="col">
          <select class="form-control" required v-model="option"
            v-on:change="results = [], showAvailableTransaction = false" :disabled='endpoint != "transactions"'>
            <option value disabled selected>Options</option>
            <option v-for="(option, value) in options" :value="option">{{ value }}</option>
          </select>
        </div>
        <div class="col">
          <select class="form-control" required v-model="country"
            v-on:change="aspsp = ''; usage = '', results = [], showAvailableTransaction = false"
            :disabled='!endpoint || (endpoint == "transactions" && !option)'>
            <option value disabled selected>Country</option>
            <option
              v-for="country in Object.keys(countries).sort((a, b) => countriesList[a].localeCompare(countriesList[b]))"
              :value="country" :key="`country-${country}`">{{ countriesList[country] }}</option>
          </select>
        </div>
        <div class="col">
          <select class="form-control" required :disabled="!country" v-model="aspsp"
            v-on:change="usage = '', results = [], showAvailableTransaction = false">
            <option value disabled selected>ASPSP</option>
            <option v-for="aspsp in countries[country]" :value="aspsp"
              :key="`aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}`">{{ aspsp.name }}</option>
          </select>
        </div>
        <div class="col">
          <select class="form-control" required :disabled="!aspsp" v-model="usage"
            v-on:change="results = [], showAvailableTransaction = false">
            <option value disabled selected>Usage type</option>
            <option v-for="usage in aspsp ? aspsp.psu_types : []" :value="usage"
              :key="`aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}-${usage}`">{{ usage }}</option>
          </select>
        </div>
        <div class="col">
          <button class="btn btn-primary" :disabled='!usage || !endpoint || (endpoint == "transactions" && !option)'
            @click="getStatistics()">
            <span v-if="optionsSelected" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Apply
          </button>
        </div>
      </div>
      <div v-if="dataAvailable">
        <data-structure :results="results" />
        <div v-if="endpoint === 'transactions' && showAvailableTransaction">
          <b-alert show class="d-flex justify-content-between mb-1" variant="secondary">Available transaction period: {{
            historicTransaction }} days</b-alert>
        </div>
      </div>
      <div v-else>
        <div style="margin-top:20px" class="text-center">
          No data
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import DataStructure from './DataStructure.vue'

export default {
  name: 'DataInsights',
  data() {
    return {
      results: [],
      loadingASPSPs: false, // when fetching ASPSPs list
      optionsSelected: false,
      countries: null,
      country: '',
      aspsp: '',
      usage: '',
      dataType: Object.freeze({
        Accounts: 'accounts',
        Balances: 'balances',
        Transactions: 'transactions'
      }),
      endpoint: '',
      options: Object.freeze({
        Debit: 'debit',
        Credit: 'credit'
      }),
      option: '',
      result: '',
      dataAvailable: false,
      historicTransaction: '',
      showAvailableTransaction: true
    }
  },
  components: {
    LoaderPic,
    DataStructure
  },
  computed: {
    ...mapGetters(['loading', 'user', 'idToken']),
    ...mapState({
      countriesList: state => state.countriesList
    })
  },
  async mounted() {
    this.loadingASPSPs = true
    try {
      const aspspsResponse = await axios.get(
        '/api/aspsps', {
          headers: {
            Authorization: `Bearer ${this.idToken.token}`
          }
        }
      )
      const countries = {}
      for (const aspsp of aspspsResponse.data.aspsps) {
        const countryASPSPs = countries[aspsp.country] || []
        aspsp.psu_types = aspsp.psu_types.sort()
        countryASPSPs.push(aspsp)
        countries[aspsp.country] = countryASPSPs.sort((a, b) => a.name.localeCompare(b.name))
      }
      this.countries = countries
    } catch (e) {
      this.pushAlertMessage({
        message: 'Unable to get ASPSPs'
      })
    } finally {
      this.loadingASPSPs = false
    }
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    unflatten(data) {
      var result = []
      var d = Object.assign({}, data)

      function transformData(key, value) {
        return {
          label: key,
          value: value,
          children: []
        }
      };

      function findByKey(key) {
        const k = [...key]
        const last = k.pop()
        if (k.length > 0) {
          const p = findByKey(k)
          return p === undefined ? undefined : p.children.find(v => v.label === last)
        }
        return result.find(v => v.label === last)
      };
      for (const [key, value] of Object.entries(d)) {
        for (const [key, value] of Object.entries(d)) {
          var leftKeys = key.split('.')
          const label = leftKeys.pop()
          if (leftKeys.length === 0) {
            result.push(transformData(label, value))
            delete d[key]
          } else if (findByKey(leftKeys) !== undefined) {
            findByKey(leftKeys).children.push(transformData(label, value))
            delete d[key]
          }
        }
      }
      return result
    },
    sortResult(data) {
      function isObject(v) {
        return Object.prototype.toString.call(v) === '[object Object]'
      };

      function sortData(o) {
        if (isObject(o)) {
          return Object
            .keys(o)
            .sort()
            .reduce(function (a, k) {
              a[k] = sortData(o[k])

              return a
            }, {})
        }

        return o
      };
      return sortData(data)
    },
    async getStatistics() {
      this.showAvailableTransaction = false
      this.optionsSelected = true
      this.results = []
      const body = {
        country: this.country,
        brand: this.aspsp.name,
        psu_type: this.usage,
        endpoint: this.endpoint,
        transaction_type: this.option
      }
      try {
        const statisticsResponse = await axios.post(
          '/api/data-insights',
          body, {
            headers: {
              Authorization: `Bearer ${this.idToken.token}`
            }
          }
        )
        var statistics = statisticsResponse.data
        console.log('oldest transaction ' + statistics.oldest_transaction)
        this.historicTransaction = statistics.oldest_transaction
        delete statistics.oldest_transaction
        this.results = this.unflatten(this.sortResult(statistics))
        this.dataAvailable = true
        this.optionsSelected = false
        this.showAvailableTransaction = true
      } catch (e) {
        console.log(e)
        this.pushAlertMessage({
          message: 'Unable to retrieve data'
        })
        this.dataAvailable = false
        this.optionsSelected = false
        this.showAvailableTransaction = false
      }
    }
  }
}
</script>
