<template>
  <div aria-live="polite" class="d-inline notifications-outer" aria-atomic="true">
    <transition-group name="alert">
      <div
        class="toast fade show bg-white"
        :class="{'toast--info': message[1].type === 'info'}"
        style="position: relative; top: 1rem; right: 1rem;"
        v-for="message in Object.entries(messagesAlertList)"
        :key="`message-${message[0]}`"
      >
        <div class="toast-header">
          <strong class="mr-auto text-info" v-if="message[1].type === 'info'">Info</strong>
          <strong class="mr-auto text-danger" v-else>An error occured</strong>
          <button
            type="button"
            class="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            v-on:click="closeAlert(message[0])"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">{{message[1].message}}</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'MessageAlert',
  computed: {
    ...mapState(['messagesAlertList'])
  },
  methods: {
    ...mapMutations(['removeAlertMessage']),
    closeAlert(key) {
      this.removeAlertMessage(key)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

$notifications-zindex: 99;

.notifications-outer {
  z-index: $notifications-zindex;
  position: fixed;
  top: 1rem;
  right: 1rem;
  .toast {
    background-color: var(--element-bg);
    z-index: 1;
    border-left: 6px solid $danger;
    &--info {
      border-color: $info;
    }
  }
}

.alert-enter-active,
.alert-leave-active {
  transform: scale(1);
  transition: all 0.2s;
}
.alert-enter,
.alert-leave-to {
  opacity: 0 !important;
}
.alert-enter {
  transform: scale(1.05);
}
.alert-leave-to {
  z-index: 0 !important;
  margin-top: -80px;
  transform: scale(0.95);
}
</style>
