<template>
  <div id="app">
    <router-view />
    <ClientOnly>
      <CookiesAlert />
    </ClientOnly>
    <MessageAlert />
  </div>
</template>

<script>
import ClientOnly from './components/ClientOnly.js'
import CookiesAlert from './components/CookiesAlert.vue'
import MessageAlert from './components/MessageAlert'

export default {
  data() {
    return {}
  },
  components: {
    ClientOnly,
    CookiesAlert,
    MessageAlert
  }
}
</script>

<style lang="scss">
@import './styles/app.scss';
</style>
