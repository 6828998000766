<template>
  <div>
    <Navbar />
    <main>
      <section class="bg-sap">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-md-7 py-4 py-md-7">
              <h1 class="text-white">Simple access to bank account<br />data for SAP users</h1>
            </div>
            <div class="col-12 col-md-5 text-right">
              <img src="../assets/sap-ecosystem.png" class="mw-100 mb-4 mb-md-0" alt="sap" />
            </div>
          </div>
        </div>
      </section>

      <article class="py-5 bg-light">
        <div class="container">
          <h3 class="mb-3 title-pic mw-800">
            Got tired of old-school integrations, far from real-time data, or not being equipped with good tools?
          </h3>
          <p class="lead mw-800">
            Good-quality, real-time data about company bank accounts is crucial when you are aiming for more automated
            processes in S/4HANA, Accounting and Financial Close, FP&A, or another SAP solution.
          </p>
        </div>
      </article>

      <section class="container py-5">
        <p class="lead mw-800">
          When using world-leading ERP software, demanding users have high expectations and should have the best and
          quickest possible way to access the bank data.
        </p>
        <p class="lead mw-800">
          With Open Banking you are able to unleash the power of SCP and Leonardo and build new solutions to meet your
          business needs easily.
        </p>
        <h4 class="mt-5 mb-5 title-pic">Open Banking to SAP implementation in few easy steps</h4>
        <p class="lead d-flex align-items-center">
          <span class="mr-3 rounded-lg h4 bg-primary-light text-primary step-circle">1</span>
          <span
            ><a href="#" @click.prevent="showMeetingModal">Meet with our team</a> and assess your business needs</span
          >
        </p>
        <p class="lead d-flex align-items-center">
          <span class="mr-3 rounded-lg h4 bg-primary-light text-primary step-circle">2</span>
          <span>Get free developer account and try the solution for real</span>
        </p>
        <p class="lead d-flex align-items-center">
          <span class="mr-3 rounded-lg h4 bg-primary-light text-primary step-circle">3</span>
          <span>Integrate with SAP Cloud Platform and get friction-less connection to SAP products</span>
        </p>
      </section>

      <section class="py-6 bg-light">
        <div class="container">
          <div class="d-flex flex-wrap align-items-center">
            <button class="btn btn-primary btn-lg my-1" @click="showMeetingModal">Book a meeting now</button>
            <span class="px-3">OR</span>
            <div>
              <button class="btn btn-primary-light btn-lg my-1" @click="showContactForm">
                Contact us for questions
              </button>
            </div>
          </div>
        </div>
      </section>

      <section class="container py-5">
        <h4 class="mb-4 title-pic">Be favoured with the benefits of our Open Banking solution</h4>
        <div class="row mb-4">
          <div class="col-12 col-md-4">
            <div class="benefit-icon bg-primary-light p-3 rounded-lg mb-3">
              <img src="../assets/tree.svg" alt="" />
            </div>
            <p class="mw-200 lead">Up-to-date technology</p>
            <ul>
              <li class="mb-3">
                <strong class="font-weight-bold">Real-time</strong> account balances and transactions
              </li>
              <li class="mb-3">
                Data from <strong class="font-weight-bold">multiple accounts and banks</strong> with a single
                integration
              </li>
              <li class="mb-3">Ready-made <strong class="font-weight-bold">OData interface</strong></li>
            </ul>
          </div>
          <div class="col-12 col-md-4">
            <div class="benefit-icon bg-primary-light p-3 rounded-lg mb-3">
              <img src="../assets/like.svg" alt="" />
            </div>
            <p class="mw-200 lead">Fast implementation</p>
            <ul>
              <li class="mb-3">
                Access to APIs <strong class="font-weight-bold">without any agreements</strong> with banks
              </li>
              <li class="mb-3">
                Data available <strong class="font-weight-bold">on the first day</strong> of an implementation project
              </li>
              <li class="mb-3">
                <strong class="font-weight-bold">No need to possess your own license</strong> from the Financial
                Supervisory Authority
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4">
            <div class="benefit-icon bg-primary-light p-3 rounded-lg mb-3">
              <img src="../assets/money-bag.svg" alt="" />
            </div>
            <p class="mw-200 lead">Reduced costs</p>
            <ul>
              <li class="mb-3">
                <strong class="font-weight-bold">10X faster</strong> implementation process compared to old ways
              </li>
              <li class="mb-3">Less work with <strong class="font-weight-bold">data cleanse</strong></li>
              <li class="mb-3"><strong class="font-weight-bold">No need to support</strong> multiple integrations</li>
            </ul>
          </div>
        </div>

        <p class="lead">
          New SAP is about automating processes that are currently done by humans on SAP systems. With automated,
          good-quality, real-time account information, you are able to provide the most advanced RPA and ML processes
          for companies who are wanting to get the most of their SAP environment.
        </p>
      </section>

      <section class="py-6 bg-light">
        <div class="container">
          <h4 class="mb-4 title-pic">Ready to try out Open Banking in SAP ecosystem?</h4>
          <div class="d-flex flex-wrap align-items-center">
            <button class="btn btn-primary btn-lg my-1" @click="showMeetingModal">Book a meeting now</button>
            <span class="px-3">OR</span>
            <div>
              <button class="btn btn-primary-light btn-lg my-1" @click="showContactForm">
                Contact us for questions
              </button>
            </div>
          </div>
          <p class="lead mt-5">
            Lose the outdated integrations, gain quick, close to the real-time bank account data &ndash; save time and
            costs. Using our knowledge and experience gathered from 20 years working in the SAP field, we can help you
            to build the most efficient solution.
          </p>
        </div>
      </section>
      <b-modal ref="meetingModalRef" size="xl" hide-footer id="meeting">
        <iframe
          frameborder="0"
          style="width: 100%; height: 580px"
          src="https://cal.mixmax.com/ugnepalijanskaite/meetingtype_716595370"
        ></iframe>
      </b-modal>
      <b-modal
        ref="contactModalRef"
        size="lg"
        hide-footer
        title="Get in touch with us!"
        id="contact"
        v-on:is-form-submitted="isFormSubmitted = $event"
      >
        <p class="mb-5" v-if="!isFormSubmitted">
          Please fill in the form with details about yourself, your business and your needs.
          <br />We're happy to hear from you and we'll get back to you soon.
        </p>
        <MainContact ref="contactFormRef" />
      </b-modal>
    </main>
    <AsideFooter />
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue/src/components/modal'
import Navbar from '../components/Navbar.vue'
import AsideFooter from '../components/AsideFooter.vue'
import MainContact from '../components/Main/MainContact.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'SapSolution',
  data() {
    return {
      isFormSubmitted: false
    }
  },
  mixins: [metadata],
  components: {
    Navbar,
    AsideFooter,
    MainContact,
    BModal
  },
  methods: {
    showContactForm() {
      this.isFormSubmitted = false
      this.$refs.contactModalRef.show()
    },
    showMeetingModal() {
      this.$refs.meetingModalRef.show()
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.bg-sap {
  background-color: #024184;
}
.sap-logo {
  max-width: 100px;
}
.step-circle {
  $size: 60px;

  min-width: $size;
  display: inline-block;
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
}

.benefit-icon {
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
