export default {
  functional: true,
  render(h, { parent, children }) {
    if (
      typeof window === 'undefined' || (
        window && window.navigator && window.navigator.webdriver
      )
    ) {
      parent.$once('hook:mounted', () => {
        parent.$forceUpdate()
      })
      return
    }
    if (parent._isMounted) {
      return children
    } else {
      parent.$once('hook:mounted', () => {
        parent.$forceUpdate()
      })
    }
  }
}
